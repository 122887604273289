/**
 * @generated SignedSource<<d1ea968d0323316dbe0b2f5d775dda9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type IssueSidebarSecondary$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AssigneesSectionLazyFragment" | "OptionsSectionSecondary">;
  readonly " $fragmentType": "IssueSidebarSecondary";
};
export type IssueSidebarSecondary$key = {
  readonly " $data"?: IssueSidebarSecondary$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssueSidebarSecondary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "useAssigneePickerV2"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IssueSidebarSecondary",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OptionsSectionSecondary"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "useAssigneePickerV2",
          "variableName": "useAssigneePickerV2"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AssigneesSectionLazyFragment"
    }
  ],
  "type": "Issue",
  "abstractKey": null
};

(node as any).hash = "9d9ea3352cbd5c36467eac95906e983a";

export default node;
