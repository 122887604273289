/**
 * @generated SignedSource<<a4b999ab237a0be25e4d12bfca9b3a12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
export type IssueState = "CLOSED" | "OPEN" | "%future added value";
export type IssueStateReason = "COMPLETED" | "DUPLICATE" | "NOT_PLANNED" | "REOPENED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type DuplicateIssuesSectionFragment$data = {
  readonly duplicateIssues: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly number: number;
      readonly repository: {
        readonly nameWithOwner: string;
      };
      readonly state: IssueState;
      readonly stateReason: IssueStateReason | null | undefined;
      readonly title: string;
      readonly url: string;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DuplicateIssuesSectionFragment";
};
export type DuplicateIssuesSectionFragment$key = {
  readonly " $data"?: DuplicateIssuesSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DuplicateIssuesSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DuplicateIssuesSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "IssueConnection",
      "kind": "LinkedField",
      "name": "duplicateIssues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Issue",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stateReason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Repository",
              "kind": "LinkedField",
              "name": "repository",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nameWithOwner",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "duplicateIssues(first:3)"
    }
  ],
  "type": "Issue",
  "abstractKey": null
};

(node as any).hash = "6e3e06089627f4d920952b9434be8a04";

export default node;
