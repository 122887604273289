import {ReadonlySectionHeader} from '@github-ui/issue-metadata/ReadonlySectionHeader'
import {Section} from '@github-ui/issue-metadata/Section'
import {ActionList, Box, Label} from '@primer/react'
import {graphql, useFragment} from 'react-relay'
import type {DuplicateIssuesSectionFragment$key} from './__generated__/DuplicateIssuesSectionFragment.graphql'
import {useMemo} from 'react'
import {Octicon} from '@primer/react/deprecated'
import type {IssueState, IssueStateReason} from '@github-ui/item-picker/IssuePickerIssue.graphql'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {IssueOpenedIcon, IssueClosedIcon, SkipIcon} from '@primer/octicons-react'

const DuplicateIssuesSectionFragment = graphql`
  fragment DuplicateIssuesSectionFragment on Issue {
    duplicateIssues(first: 3) {
      nodes {
        id
        number
        title
        state
        stateReason
        url
        repository {
          nameWithOwner
        }
      }
    }
  }
`

type DuplicateIssuesSectionProps = {
  issue: DuplicateIssuesSectionFragment$key
}

export function DuplicateIssuesSection({issue}: DuplicateIssuesSectionProps) {
  const semanticIssuesEnabled = useFeatureFlag('elasticsearch_semantic_indexing_issues_show_dupes')
  if (!semanticIssuesEnabled) {
    return null
  }

  return <DuplicateIssuesSectionInternal issue={issue} />
}

function DuplicateIssuesSectionInternal({issue}: DuplicateIssuesSectionProps) {
  const data = useFragment(DuplicateIssuesSectionFragment, issue)

  const duplicateIssues = useMemo(() => {
    return data.duplicateIssues?.nodes?.filter(node => !!node) || []
  }, [data.duplicateIssues])

  return (
    <Section
      id="duplicate-issues-section"
      sectionHeader={
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          <ReadonlySectionHeader title="Related issues" />
          <Label variant="accent">Staff</Label>
        </Box>
      }
      emptyText={duplicateIssues.length ? undefined : 'No related issues found'}
    >
      <ActionList variant="full">
        {duplicateIssues.map(duplicate => (
          <ActionList.LinkItem key={duplicate.id} href={duplicate.url} target="_blank">
            <ActionList.LeadingVisual>
              <IssueIcon state={duplicate.state} stateReason={duplicate.stateReason} />
            </ActionList.LeadingVisual>
            {duplicate.title}
            <ActionList.Description variant="block">
              {duplicate.repository.nameWithOwner}#{duplicate.number}
            </ActionList.Description>
          </ActionList.LinkItem>
        ))}
      </ActionList>
    </Section>
  )
}

function IssueIcon({state, stateReason}: {state: IssueState; stateReason: IssueStateReason | null | undefined}) {
  let icon = IssueOpenedIcon
  let fill = 'open.fg'

  if (state === 'CLOSED') {
    icon = stateReason === 'COMPLETED' ? IssueClosedIcon : SkipIcon
    fill = stateReason === 'COMPLETED' ? 'done.fg' : 'muted.fg'
  }

  return <Octicon icon={icon} sx={{path: {fill}}} />
}

try{ DuplicateIssuesSection.displayName ||= 'DuplicateIssuesSection' } catch {}
try{ DuplicateIssuesSectionInternal.displayName ||= 'DuplicateIssuesSectionInternal' } catch {}
try{ IssueIcon.displayName ||= 'IssueIcon' } catch {}